import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../atoms/modal'
import { Button, If } from '@techmobilt/ui-components'
import { useTranslation } from '../../../context/translationProvider'
import {
  StyledFirstStepContainer,
  StyledFirstStepContent,
  StyledModalContentWrapper,
  StyledVerificationMessage,
  StyledVerificationStatusTitle 
} from './styles'
import { Image } from '../../atoms/image'
import selfieAndIdIcon from '../../../images/icon_selfie_and_id_gray.svg'
import Checkmark from '../../../images/icon_circle_check_green_dark.svg'
import SupportChatIcon from '../../../images/icon_support_chat.svg'
import icon_selfie_and_id_failed from '../../../images/icon_selfie_and_id_red.svg'
import { toast } from '../../atoms'
import { themeStyles } from '../../../styles/theme'
import { AuthContext } from '../../../context/authProvider'
import { isMobile } from '../../../utils/generic'
import { CafIFrame } from '../../molecules/cafIFrame'
import {
  CAF_MESSAGE_CODES,
  FIVE_SECONDS,
  KycStatus 
} from '../../../utils/constants'
import { getKYCRejectReasons, getKYCVerificationData } from '../../../adapters/kyc'
import storageService from '../../../services/storageService'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { StyledCheckedIcon } from '../registerContent/styles'
import { useEventTracking } from '../../../hooks/useEventTracking'
import { InfoBox } from '../../molecules/InfoBox'
import { useReCaptcha } from '../../../hooks/useReCaptcha'
import { getUserMigratableStatus } from '../../../utils/getUserMigratableStatus'
import { useDebounce } from '../../../hooks/useDebounce'
import { Script } from 'gatsby'
import { EnvConfig } from '../../../config/EnvConfig'
import { useFeatureValue } from '@growthbook/growthbook-react'

export const KYCVerifyAccountModal = ({ 
  isOpen,
  onClose = () => null,
  onFinish = () => null,
}) => {
  const { open: openDepositDrawer } = usePaymentDrawerContext()
  const { logoutUser, safeSetUser } = useContext(AuthContext)
  const { translate, lang } = useTranslation()
  const [step, setStep] = useState(0)
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()
  const [userHasBalance, setUserHasBalance] = useState(false)
  const [rejectReason, setRejectReason] = useState(null)
  const IS_OLD_BALANCE_ENABLED = 
    useFeatureValue('fe_ipg_old_balance_warning_enabled', false)

  const SHOW_OLD_BALANCE_WARNING = userHasBalance && IS_OLD_BALANCE_ENABLED

  const recaptchaGetUserMigratableStatus = useReCaptcha(
    getUserMigratableStatus, 'IS_MIGRATEABLE'
  )

  const getBalanceStatus = async () => {
    try {
      const user = storageService.getUser()
      const { 
        hasBalance
      } = await recaptchaGetUserMigratableStatus({ 
        email: user.email
      })
      setUserHasBalance(hasBalance)
    } catch (err) {
      console.error(err)
    }
  }

  const debounceGetBalanceStatus = useDebounce(getBalanceStatus, 500)

  useEffect(() => {
    if (isOpen) {
      debounceGetBalanceStatus()
    }
  }, [isOpen])

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  useEffect(() => {
    if (!isOpen) return

    const { SCREEN_LOADED } = EVENT_NAMES
    const { REGISTER_VERIFY_ACCOUNT, REGISTER_ACCOUNT_VERIFIED } = EVENT_LOCATIONS

    if (step === 0)
      return trackEvent(SCREEN_LOADED, {
        screen_name: REGISTER_VERIFY_ACCOUNT,
      })

    if (step === 1)
      return trackEvent(SCREEN_LOADED, {
        screen_name: REGISTER_ACCOUNT_VERIFIED,
      })
  }, [step, isOpen])

  useEffect(() => {
    if (!isOpen || !lang) return
    const registrationCompletedToast 
      = storageService.getValue('registrationCompletedToast')

    const showToast = registrationCompletedToast === 'show'

    if (showToast) {
      toast.success(translate('register.lead.success'))
      storageService.removeValue('registrationCompletedToast')
    }
  }, [isOpen, lang])

  const closeCafIFrame = (status) => {
    if (status !== CAF_MESSAGE_CODES.ONBOARDING_FINISHED) return

    const poolingInterval = setInterval(async () => {
      const response = await getKYCVerificationData()
      const status = response?.data?.identificationAndSelfie?.status || null
      if (status === KycStatus.APPROVED) {
        clearInterval(poolingInterval)
        await safeSetUser()
        setStep(2)
        return
      }

      const KYC_REJECTED = 
        status === KycStatus.REJECTED || status === KycStatus.REPROVED
      
      if (KYC_REJECTED) {
        clearInterval(poolingInterval)
        const { data: rejectReasonData } = 
          await getKYCRejectReasons(response?.data?.identificationAndSelfie?.id)

        setRejectReason(rejectReasonData?.[0])
        setStep(3)
        return
      }
    }, FIVE_SECONDS)
  }

  const handleDeposit = () => {
    onClose()
    openDepositDrawer()
    onFinish()
  }

  const handleLogout = () => {
    logoutUser()
    onClose()
  }

  return (
    <Modal
      theme="form"
      isOpen={isOpen}
      onClose={onClose}
      title={translate('account.verification.verification')}
      hideClose
    >
      <StyledModalContentWrapper>

        <If 
          condition={step === 3}
          render={() => (
            <StyledFirstStepContainer>
              <StyledVerificationStatusTitle>
                {translate('registration.selfieAndId.failTitle')}
              </StyledVerificationStatusTitle>

              <StyledCheckedIcon url={icon_selfie_and_id_failed} isFail />

              <StyledVerificationMessage>
              {rejectReason || translate('registration.selfieAndId.failDescription')}
              </StyledVerificationMessage>
      
              <Button $block onClick={() => setStep(1)}>
                {translate('common.tryAgain')}
              </Button>

              <Button
                $layout="link"
                $colorSchema="black"
                $weight={400}
                style={{ textDecoration: 'underline', padding: 0 }}
                onClick={handleLogout}
              >
                {translate('navigation.logout')}
              </Button>
            </StyledFirstStepContainer>
          )} 
        />

        <If 
          condition={step === 0} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
                <StyledVerificationStatusTitle>
                  {translate('account.verfication.title')}
                </StyledVerificationStatusTitle>

                <Image url={selfieAndIdIcon} />

                <StyledVerificationMessage>
                  {translate('account.verfication.description')}
                </StyledVerificationMessage>
              </StyledFirstStepContent>
              <StyledFirstStepContent>
                <Button
                  $colorSchema="light"
                  $weight={400}
                  style={{ 
                    backgroundColor: themeStyles.colors.lightGreys[1].main 
                  }}
                  $roundeed
                  $size="sm"
                  onClick={toggleContactUs}
                  $leftIcon={<Image url={SupportChatIcon} />}
                >
                  {translate('account.verfication.contactSupport')}
                </Button>

                <Button $block onClick={() => setStep(1)}>
                  {translate('account.verfication.activateYourAccount')}
                </Button>
                <Button
                  $layout="link"
                  $colorSchema="black"
                  $weight={400}
                  style={{ textDecoration: 'underline', padding: 0 }}
                  onClick={handleLogout}
                >
                  {translate('navigation.logout')}
                </Button>

                <If 
                  condition={SHOW_OLD_BALANCE_WARNING}
                  render={() => (
                    <InfoBox 
                      title={translate('account.verification.balance.title')}
                      content={translate('account.verification.balance.content')}
                    />
                  )}
                />
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />

        <If
          condition={step === 1}
          render={() => (
            <CafIFrame
              minHeight={isMobile() ? '90vh' : '75vh'}
              chosenDocument={'IDENTIFICATION_AND_SELFIE'}
              closeIFrame={closeCafIFrame}
              requestParams={{ requestType: 'REGISTRATION_REQUEST' }}
            />
          )}
        />

        <If 
          condition={step === 2} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
              <Image url={Checkmark} />

              <StyledVerificationStatusTitle>
                {translate('register.verificationSuccess')}
              </StyledVerificationStatusTitle>
              <StyledVerificationMessage>
                {translate('register.verificationSuccessDescription')}
              </StyledVerificationMessage>
            </StyledFirstStepContent>

            <StyledFirstStepContent style={{ gap: 15 }}>
              <Button $block onClick={handleDeposit}>
                {translate('navigation.deposit')}
              </Button>

              <Button
                $block
                onClick={onClose}
                $layout="outline"
                $colorSchema="black"
              >
                {translate('common.notNow')}
              </Button>
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />
      </StyledModalContentWrapper>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${EnvConfig.GATSBY_RECAPTCHA_KEY}`}
      />
    </Modal>
  )
}

KYCVerifyAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
}
