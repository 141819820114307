import mixpanel from 'mixpanel-browser'
import { isBrowser } from '../utils/generic'

export const useEventTracking = () => {

  const trackEvent = (eventName, properties = {}) => {
    if (!isBrowser() || !eventName) return

    const extendedProperties = {
      ...properties,
      env: process.env.GATSBY_MIXPANEL_ENV,
    }

    mixpanel.track(eventName, extendedProperties)
  }

  const identifyUser = (id) => {
    if (!id) return
    mixpanel.identify(id.toString())
  }

  const EVENT_NAMES = {
    SCREEN_LOADED: 'Screen Loaded',
    PERSONAL_DATA_COMPLETED: 'Personal Data Completed',
    PARTIALLY_REGISTERED_USER: 'Partially Registered User',
    FULLY_REGISTERED_USER: 'Fully Registered User',
    EMAIL_INVALID_CODE_RETURNED: 'Email Invalid Code Returned',
    PHONE_INVALID_CODE_RETURNED: 'Phone Invalid Code Returned',
    LOCATION_SHARING_ENABLED: 'Location Sharing Enabled',
    LOCATION_SHARING_DISABLED: 'Location Sharing Disabled',
  }

  const EVENT_LOCATIONS = {
    REGISTER_START: 'Register Start',
    REGISTER_EMAIL_VERIFICATION: 'Register Email Verification',
    REGISTER_PHONE: 'Register Phone',
    REGISTER_PHONE_VERIFICATION: 'Register Phone Verification',
    REGISTER_ADDRESS: 'Register Address',
    REGISTER_VERIFY_ACCOUNT: 'Register Verify Account',
    REGISTER_ACCOUNT_VERIFIED: 'Register Account Verified',
    REGISTER_QUIT_CONFIRMATION: 'Register Quit Confirmation',
    ADD_BANK_ACCOUNT_START: 'Add Bank Account Start',
    ADD_BANK_ACCOUNT_DETAILS: 'Add Bank Account Details',
    DEPOSIT: 'Deposit',
    WITHDRAWAL: 'Withdrawal',
    PIX: 'Pix',
    LOCATION_PERMISSION: 'Location Permission',
  }

  return { trackEvent, identifyUser, EVENT_NAMES, EVENT_LOCATIONS }
}
