import { themeStyles, themeMedia } from '../../../styles/theme'
import styled from 'styled-components'
import { brandConfig } from '../../../config/brandConfig'
import { Image } from '../../atoms/image'
import { Text } from '../../atoms/text'
import { Button } from '../../atoms/button'

export const InstructionsWrapper = styled.div`
  border-radius: 8px;
  background-color: #efefef;
  padding: 15px;
  margin-bottom: 35px;
`

export const MobileBanner = styled.div`
  display: none;
  ${themeMedia.maxTablet`
    display: block;
    position: fixed;
    bottom: 50px;
    left: 50px;
    right: 50px;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: ${themeStyles.colors.white.main};
  `}
`

export const StyledTopBanner = styled.div`
  display: flex;
  min-height: 56px;
  background-color: ${themeStyles.colors.dominantGrey.main};
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  button {
    height: 40px;
    display: flex;
    justify-content: center;
  }
  ${themeMedia.maxTablet`
    display: block;
    padding: 15px;
    button {
      width: 100%;
      margin-top: 10px;
    }
  `}
  p {
    margin-bottom: 0;
    color: ${themeStyles.colors.white.main};
    font-size: 12px;
  }
`

export const StyledModalWrapper = styled.div`
  padding: 0 1rem;
  text-align: center;
  max-width: 100%;
  margin-top: 70px;
  ${themeMedia.tablet`
    padding: 0;
    max-width: 75%;
    margin: auto;
  `}
`

export const StyledWrapper = styled.div`
  padding: 0 1rem;
  text-align: center;
  max-width: 100%;
  ${themeMedia.tablet`
    padding: 0;
    max-width: 75%;
    margin: auto;
  `}
`

export const StyledTitle = styled.h1`
  font-size: 1.375em;
  font-weight: 600;
  margin: 30px auto;
`

export const StyledImage = styled(Image)`
  margin: 0 auto 30px;
  width: '100px';
`

export const StyledUl = styled.ul`
  text-align: left;
  ${themeMedia.maxTablet`
    max-height: calc(100vh - 555px);
    overflow-y: auto;
  `}
`

export const StyledLi = styled.li`
  font-weight: ${brandConfig.fonts.text.paragraph.weight};
  font-size: ${brandConfig.fonts.text.paragraph.size.mobile};
  line-height: 1.45em;
`

export const StyledText = styled(Text)`
  text-align: center;
`

export const StyledButton = styled(Button)`
  ${themeMedia.maxTablet`
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    width: calc(100% - 48px);
    margin: auto;
  `} 
`