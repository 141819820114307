import { getMigrateableStatus } from '../../adapters/auth'

export const getUserMigratableStatus = async ({ email, recaptchaToken }) => {
  const migrateableStatusResponse = await getMigrateableStatus({
    email, 
    recaptchaToken 
  })

  const IS_REQUEST_SUCCESSFUL = migrateableStatusResponse?.ok

  if (!IS_REQUEST_SUCCESSFUL) {
    return { isMigratable: false, nationalId: null, hasBalance: false }
  }

  const IS_MIGRATABLE = migrateableStatusResponse?.data?.migrateable

  const isMigratable = IS_REQUEST_SUCCESSFUL && IS_MIGRATABLE

  const { 
    nationalId, 
    hasBalance,
    phone,
    phonePrefix,
    state,
    city,
    address,
  } = migrateableStatusResponse?.data || {}

  return { 
    isMigratable, 
    hasBalance, 
    userData: {
      nationalId, 
      phone,
      phonePrefix,
      state,
      city,
      address,
    } 
  }
}