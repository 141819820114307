import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../context/translationProvider'
import accessDeniedImg from './../../../images/access-denied.svg'
import { If } from './../../atoms/if'
import { Modal } from '../../atoms/modal'
import useSlugs from '../../../hooks/useSlugs'
import { 
  StyledImage,
  StyledLi,
  StyledText,
  StyledTitle,
  StyledUl,
  StyledModalWrapper,
  StyledButton
} from './style'
import useModal from '../../../hooks/useModal'
import { navigate } from 'gatsby'

export const RadarErrorModal = (props) => {

  const { radarResponse } = props

  const { translate } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [casinoSlug, liveCasinoSlug] = useSlugs(['casino', 'live-casino'])
  const gameSlug = `${casinoSlug}/game/`
  const radarErrorSlugs = [casinoSlug, liveCasinoSlug]
  const { closeModal } = useModal()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'radar_error'
      })
    }
  }, [])

  useEffect(() => {
    if (!radarResponse || !radarErrorModalEnabled()) return
  
    if (!radarResponse.passed && window.location.href.includes(gameSlug)) {
      navigate(`/${casinoSlug}`)
      return
    }
  
    setIsOpen(!radarResponse.passed)
  }, [radarResponse])

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  const radarErrorModalEnabled = () => {
    return !window.isIntercomOpen && radarErrorSlugs.some(slug => window.location.href.includes(slug))
  }

  return (
    <Modal
      theme="payments"
      title={translate('radar.error.modalHeading')}
      onClose={closeModal}
      isOpen={isOpen}
      hideClose
    >
      <StyledModalWrapper>
        <StyledTitle>{translate('radar.error.modalTitle')}</StyledTitle>
        <StyledImage url={accessDeniedImg}/>
        <div style={{ marginBottom: '30px' }}>
          <If
            condition={radarResponse?.reasons?.length === 1}
            render={() => 
              <StyledText>
                {translate(`radar.error.${radarResponse?.reasons[0]}`)} <span
                  dangerouslySetInnerHTML={{
                    __html: translate('radar.error.foundOutMoreSingle'),
                  }}
                />
              </StyledText>
            }
            renderElse={() => 
              <>
                <StyledText>{translate(`radar.error.denialReasons`)}</StyledText>
                <StyledUl>
                  {radarResponse?.reasons.map((reason, index) => (
                    <StyledLi>{translate(`radar.error.${reason}`)}</StyledLi>
                  ))}
                </StyledUl>
                <StyledText><span
                  dangerouslySetInnerHTML={{
                    __html: translate(`radar.error.foundOutMoreMultiple`),
                  }}
                /></StyledText>
              </>
            }
          />
        </div>
        <StyledButton onClick={() => toggleContactUs()}>
          {translate('geolocation.contactSupport')}
        </StyledButton>
      </StyledModalWrapper>
    </Modal>
  )
}
