import React from 'react'
import PropTypes from 'prop-types'
import { StyledContent, StyledIconWrapper, StyledWrapper } from './styles'
import { getBankIcon } from '../../../utils/bankIcons'
import tickIcon from '../../../images/black_tick.svg'
import { Text } from '../../atoms/text'
import { Image } from '../../atoms/image'

export const BankCard = ({
  isChecked = false,
  onClick = () => {},
  bank = {},
}) => {
  const handleClick = () => {
    onClick(bank)
  }

  const bankIcon = isChecked
    ? tickIcon
    : getBankIcon(bank?.code?.toUpperCase(), true)

  return (
    <StyledWrapper
      $isChecked={isChecked}
      onClick={handleClick}
    >
      <StyledIconWrapper>
        <Image url={bankIcon} alt={bank.name} width={40} />
      </StyledIconWrapper>
      <StyledContent>
        <Text small>
          {bank.name}
        </Text>
      </StyledContent>
    </StyledWrapper>
  )
}

BankCard.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  bank: PropTypes.shape({
    name: PropTypes.string,
    fullName: PropTypes.string,
  }),
}